import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function BookPost({ data }) {
  const post = data.mysqlLectura
  return (
    <Layout>
      <div>
        <h1>{post.titulo}</h1>
        <img src={`data:image/png;base64,${post.portada}`}/>
        <div dangerouslySetInnerHTML={{ __html: post.contenido }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: Int!) {
    mysqlLectura(mysqlId: {eq: $slug}) {
      titulo
      sinopsis
      portada
      contenido
      id
    }
  }
`

export const Head = () => <Seo title="Using DSG" />

